@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  font-family: "Poppins", sans-serif;
  background-color: #1f1f1f;
  overflow-x: hidden;
}
* {
  cursor: none;
  scroll-behavior: smooth;
  background-color: #1f1f1f;
}

@menu-dark-item-active-bg: #222222;