.welcome-hi {
  font-size: 20px;
}
.name {
  font-size: 70px;
}
.short-desc {
  font-size: 60px;
  margin-top: -75px;
}
.long-desc {
  font-size: 16px;
  margin-top: -50px;
}
@media screen and (min-width: 3840px) {
  .welcome-hi {
    font-size: 30px;
  }
  .name {
    font-size: 80px;
  }
  .short-desc {
    font-size: 70px;
    margin-top: -75px;
  }
  .long-desc {
    font-size: 26px;
    margin-top: -50px;
  }
}
@media screen and (max-width: 1140px) {
  .welcome-hi {
    font-size: 16px;
  }
  .name {
    font-size: 60px;
  }
  .short-desc {
    font-size: 50px;
    margin-top: -75px;
  }
  .long-desc {
    font-size: 16px;
    margin-top: -50px;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1178px) {
  .welcome-hi {
    font-size: 13px;
  }
  .name {
    font-size: 60px;
  }
  .short-desc {
    font-size: 50px;
    margin-top: -75px;
  }
  .long-desc {
    font-size: 13px;
  }
}
@media screen and (max-width: 878px) {
  .welcome-hi {
    font-size: 16px;
  }
  .name {
    font-size: 50px;
  }
  .short-desc {
    font-size: 40px;
    margin-top: -50px;
  }
  .long-desc {
    font-size: 16px;
    margin-top: -30px;
  }
}
@media screen and (max-width: 702px) {
  .welcome-hi {
    font-size: 16px;
  }
  .name {
    font-size: 40px;
  }
  .short-desc {
    font-size: 30px;
    margin-top: -40px;
  }
  .long-desc {
    font-size: 15px;
    margin-top: -20px;
  }
}
@media screen and (max-width: 474px) {
  .welcome-hi {
    font-size: 16px;
  }
  .name {
    font-size: 40px;
  }
  .short-desc {
    font-size: 25px;
    margin-top: -30px;
  }
  .long-desc {
    font-size: 13px;
    margin-top: -10px;
  }
}
